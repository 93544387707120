import React from 'react';
import clsx from 'clsx';
import Layout from 'components/Layout';
import { Grid, Box, Hidden } from '@material-ui/core';
import AnimateInView from 'components/AnimateInView';

import FormRegister from 'components/FormRegister';
import useStyles from './styles';

type IComponentProps = {};

const Team: React.FC<IComponentProps> = props => {
  const classes = useStyles();

  return (
    <Layout>
      <Box className={clsx(classes.box1)}>
        <Hidden smDown>
          <div className={classes.imageWrapper}>
            <AnimateInView directionFrom="top">
              <img src={require('assets/images/home-bg.png')} alt="" />
            </AnimateInView>
          </div>
        </Hidden>

        <Grid container justify="space-around" className={classes.teamBox}>
          <Grid
            container
            direction="column"
            item
            md={5}
            xs={12}
            className={classes.team1}
          >
            <AnimateInView directionFrom="left">
              <div className={classes.avatar}>
                <img src={require('./images/team2.png')} alt="" />
              </div>
            </AnimateInView>
            <AnimateInView directionFrom="left">
              <div className={classes.teamText}>
                <div className={classes.teamTextTitle}>FY PROPERTY</div>
                <div className={classes.teamTextDetail}>
                  FY Property is an Australian based developer specialised in
                  high-end property developments in Sydney North Shore. The
                  company has built strategic partnership with some of the best
                  international architects and designers in order to achieve
                  Innovation and Craftsmanship in every single development.
                  <div style={{ width: 80, marginTop: 8 }}>
                    <img src={require('./images/extra2.png')} />
                  </div>
                </div>
              </div>
            </AnimateInView>

            <AnimateInView directionFrom="left" className={classes.sub}>
              <div className={classes.avatar}>
                <img src={require('./images/team1.jpeg')} alt="" />
              </div>
            </AnimateInView>
            <AnimateInView directionFrom="left" className={classes.sub2}>
              <div className={classes.teamText}>
                <div className={classes.teamTextTitle}>Tony Leung</div>
                <div className={classes.teamTextDetail}>
                  Since Tony Leung founded a+ design group in 2009, it has grown
                  rapidly; it’s now a recognisable firm in the residential and
                  hotels sector. A registered architect, Tony brings over 20
                  years of masterplanning, urban design and architectural
                  experience to his practice. He is a rare combination of
                  business acumen and award winning design skills. His extensive
                  knowledge in the field has seen him as a visiting critic at
                  the University Of Sydney School Of Architecture. His work is
                  known for its’ clarity of intent and resolution. Tony’s
                  research in current and future ways of life enables a+ design
                  group to create architecture that is relevant and unique.
                  <div style={{ width: 60, marginTop: 8 }}>
                    <img src={require('./images/extra1.png')} />
                  </div>
                </div>
              </div>
            </AnimateInView>
          </Grid>
          <Grid item md={5} xs={12}>
            <AnimateInView directionFrom="right">
              <div className={classes.avatar}>
                <img src={require('./images/team3.jpg')} alt="" />
              </div>
            </AnimateInView>
            <AnimateInView directionFrom="right">
              <div className={classes.teamText}>
                <div className={classes.teamTextTitle}>A+ Design Group</div>
                <div className={classes.teamTextDetail}>
                  a+ design group’s vision is to create spaces that bring family
                  together, as well as positively connect people with their
                  surroundings. Their award-winning architectural practice
                  specialises in the spaces in which people live.
                  Multi-residential and 5-star hotel designs are tailored with
                  light and air. Renowned for their elegant detailing and
                  contemporary aesthetic style, a+ design group create spaces
                  that cater to the demand of modern families.
                  <div style={{ width: 60, marginTop: 8 }}>
                    <img src={require('./images/extra1.png')} />
                  </div>
                </div>
              </div>
            </AnimateInView>
            <Grid className={classes.team4}>
              <AnimateInView directionFrom="right">
                <div className={classes.avatar}>
                  <img src={require('./images/team4.png')} alt="" />
                </div>
              </AnimateInView>
              <AnimateInView directionFrom="right">
                <div className={classes.teamText}>
                  <div className={classes.teamTextDetail}>
                    Part of RMA GROUP, RMA PROJECTS specializes in real estate
                    projects sales and marketing, providing comprehensive
                    expertise and excellent service for our clients from concept
                    to delivery. We pride ourselves on our professionalism,
                    dedication, and outstanding results. Our team is capable of
                    providing valuable advices in all related sectors with our
                    accurate local market knowledge, profound experience and
                    unique insights. Instantly motivated by the corporate
                    slogan, "Property, People, Passion”, we offer real estate
                    buyers and developers area intelligence and seamless
                    tailor-made one-stop solutions that ensures optimal and
                    desired results in a tremendously promising property market.
                  </div>
                </div>
              </AnimateInView>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.box2}>
        <div className="title">Strategic Partnership</div>

        <Grid container alignItems="center" justify="space-around">
          <Grid
            container
            alignItems="center"
            justify="center"
            className="logoItem"
          >
            <img src={require('./images/logo1.png')} alt="" />
          </Grid>

          <Grid
            container
            alignItems="center"
            justify="center"
            className="logoItem"
          >
            <img src={require('./images/logo4.png')} alt="" />
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            className="logoItem"
          >
            <img src={require('./images/logo5.png')} alt="" />
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            className="logoItem"
          >
            <img src={require('./images/logo6.png')} alt="" />
          </Grid>
        </Grid>
      </Box>
      <FormRegister />
    </Layout>
  );
};

export default Team;
