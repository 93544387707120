import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  Grid,
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Slide,
  Dialog,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { useRouteMatch } from 'react-router-dom';
import Dehaze from '@material-ui/icons/Dehaze';
import Close from '@material-ui/icons/Close';

import AnimateInView from 'components/AnimateInView';
import * as HomeLogo from 'assets/images/home-bg.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      position: 'relative',
    },

    appbar: {
      boxShadow: 'none',
      paddingLeft: 50,
      paddingRight: 50,
    },
    toolbar: {
      height: 80,
    },
    linkWrapper: {
      '&+$linkWrapper': {
        marginLeft: 20,
        marginRight: 20,
      },
    },
    link: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
      fontSize: 14,
      position: 'relative',
      letterSpacing: 4,
      backgroundImage: 'linear-gradient(to right, #BA7D16, #F2CA48)',
      '-webkit-background-clip': 'text',
      backgroundClip: 'text',
      textFillColor: 'transparent',
      '&.active::after': {
        width: '100%',
        backgroundImage:
          'linear-gradient(to right, rgba(186, 125, 122, .5), rgba(242, 202, 72, 0.5))',
      },
      '&.active:hover::after': {
        width: '100%',
        backgroundImage: 'linear-gradient(to right, #BA7D16, #F2CA48)',
      },
      '&+$link': {
        marginLeft: 20,
        marginRight: 20,
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -2,
        left: 0,
        width: 0,
        height: 1,
        backgroundImage: 'linear-gradient(to right, #BA7D16, #F2CA48)',
        transition: 'width .3s ease-out',
      },
      '&:hover::after': {
        width: '100%',
      },
    },
    homeLogo: {
      background: `url(${HomeLogo}) center center no-repeat`,
      textIndent: -999,
      color: 'transparent',
      backgroundSize: 'contain',
      display: 'block',
      width: 120,
      height: 70,
    },

    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    dehaze: {
      position: 'absolute',
      right: 10,
      top: '50%',
      color: 'white',
      marginTop: -20,
      zIndex: theme.zIndex.snackbar,
    },
    backdrop: {
      '& .MuiDialog-paperFullScreen': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
);

const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) => {
    return <Slide direction="down" ref={ref} {...props} />;
  },
);

export default function PrimaryAppBar() {
  const classes = useStyles();
  const [isDialogVisible, setDialogVisible] = React.useState<boolean>(false);

  const matchPageTeam = useRouteMatch('/team');

  return (
    <div className={classes.header}>
      <AppBar className={classes.appbar} position="static">
        <Toolbar className={classes.toolbar}>
          <Grid
            container
            alignItems="center"
            justify={matchPageTeam ? 'center' : 'space-between'}
            wrap="nowrap"
          >
            <Hidden smDown>
              <Grid
                container
                item
                sm={3}
                justify={matchPageTeam ? 'flex-end' : 'flex-start'}
              >
                <AnimateInView
                  directionFrom="top"
                  className={classes.linkWrapper}
                >
                  <NavLink
                    activeClassName="active"
                    exact
                    to="/"
                    className={classes.link}
                  >
                    DESIGN
                  </NavLink>
                </AnimateInView>
                <AnimateInView
                  directionFrom="top"
                  className={classes.linkWrapper}
                >
                  <NavLink
                    activeClassName="active"
                    to="/location"
                    className={classes.link}
                  >
                    LOCATION
                  </NavLink>
                </AnimateInView>
              </Grid>
            </Hidden>

            {!matchPageTeam && (
              <Grid container alignItems="center" justify="center" item sm>
                <Grid item>
                  <AnimateInView directionFrom="top">
                    <Link to="/" className={classes.homeLogo}>
                      home
                    </Link>
                  </AnimateInView>
                </Grid>
              </Grid>
            )}

            <Hidden smDown>
              <Grid
                container
                item
                sm={3}
                justify={matchPageTeam ? 'flex-start' : 'flex-end'}
              >
                <AnimateInView
                  directionFrom="top"
                  className={classes.linkWrapper}
                >
                  <NavLink
                    activeClassName="active"
                    to="/team"
                    className={classes.link}
                  >
                    TEAM
                  </NavLink>
                </AnimateInView>
                <AnimateInView
                  directionFrom="top"
                  className={classes.linkWrapper}
                >
                  <NavLink
                    activeClassName="active"
                    to="/register"
                    className={classes.link}
                  >
                    REGISTER
                  </NavLink>
                </AnimateInView>
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <IconButton
          className={classes.dehaze}
          onClick={() => setDialogVisible(!isDialogVisible)}
        >
          <AnimateInView directionFrom="right">
            {isDialogVisible ? <Close /> : <Dehaze />}
          </AnimateInView>
        </IconButton>
        <Dialog
          className={classes.backdrop}
          open={isDialogVisible}
          fullScreen
          TransitionComponent={Transition}
          onClick={() => setDialogVisible(false)}
        >
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ height: '100%' }}
            spacing={5}
          >
            <Grid item>
              <NavLink
                activeClassName="active"
                exact
                className={classes.link}
                to="/"
              >
                DESIGN
              </NavLink>
            </Grid>
            <Grid item>
              <NavLink
                activeClassName="active"
                className={classes.link}
                to="/location"
              >
                LOCATION
              </NavLink>
            </Grid>
            <Grid item>
              <NavLink
                activeClassName="active"
                className={classes.link}
                to="/team"
              >
                TEAM
              </NavLink>
            </Grid>
            <Grid item>
              <NavLink
                activeClassName="active"
                className={classes.link}
                to="/register"
              >
                REGISTER
              </NavLink>
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
    </div>
  );
}
