import React from 'react';
import clsx from 'clsx';
import Layout from 'components/Layout';
import { Grid, Box, useTheme } from '@material-ui/core';
import AnimateInView from 'components/AnimateInView';

import FormRegister from 'components/FormRegister';
import useStyles from './styles';

type IComponentProps = {};

const Location: React.FC<IComponentProps> = props => {
  const classes = useStyles();

  return (
    <Layout>
      <Box>
        <img
          style={{ display: 'block', width: '100%' }}
          src={require('./images/head.jpg')}
          alt=""
        />
      </Box>
      <Box className={clsx(classes.box1)}>
        <Grid container alignItems="flex-start" className="content">
          <Grid item md={9} sm={12} className="image">
            <img src={require('./images/box2.png')} alt="" />
          </Grid>
          <Grid
            item
            container
            md={3}
            sm={12}
            spacing={1}
            alignItems="flex-start"
            className="text"
          >
            <Grid item container xs={12} direction="column" className="title">
              <AnimateInView directionFrom="bottom">
                <div style={{ color: 'white' }}>The Heart of</div>
              </AnimateInView>
              <AnimateInView directionFrom="bottom">
                <div className="titleBold">CHATSWOOD</div>
              </AnimateInView>
            </Grid>

            <Grid item md={12} sm={6} xs={12} className={classes.extraText}>
              <AnimateInView directionFrom="bottom">
                <h5 className={classes.extraTitle}>RETAIL</h5>
                <p className={classes.extraTextItem}>
                  Chatswood Chase Shopping Centre
                </p>
                <p className={classes.extraTextItem}>Westfield Chatswood</p>
                <p className={classes.extraTextItem}>
                  Chatswood Interchange District Dining
                </p>
                <p className={classes.extraTextItem}>Mandrin Centre</p>
              </AnimateInView>
            </Grid>

            <Grid item md={12} sm={6} xs={12} className={classes.extraText}>
              <AnimateInView directionFrom="bottom">
                <h5 className={classes.extraTitle}>TRANSPORT</h5>
                <p className={classes.extraTextItem}>
                  Chatswood Train & Bus Station
                </p>
                <p className={classes.extraTextItem}>Chatswood Metro Station</p>
                <p className={classes.extraTextItem}>Express City Bus</p>
              </AnimateInView>
            </Grid>

            <Grid item md={12} sm={6} xs={12} className={classes.extraText}>
              <AnimateInView directionFrom="bottom">
                <h5 className={classes.extraTitle}>SCHOOLS</h5>
                <p className={classes.extraTextItem}>
                  Willoughby Public School{' '}
                  <span className="colorSecondary">(1.5 Km)</span>
                </p>
                <p className={classes.extraTextItem}>
                  Willoughby Girls High School{' '}
                  <span className="colorSecondary">(1.6 Km)</span>
                </p>
                <p className={classes.extraTextItem}>
                  Chatswood Public School{' '}
                  <span className="colorSecondary">(1.9 Km)</span>
                </p>
                <p className={classes.extraTextItem}>
                  Chatswood High School{' '}
                  <span className="colorSecondary">(2.1 Km)</span>
                </p>
                <p className={classes.extraTextItem}>
                  St Pius X College{' '}
                  <span className="colorSecondary">(1.4 Km)</span>
                </p>
                <p className={classes.extraTextItem}>
                  Ravenswood School for Girls{' '}
                  <span className="colorSecondary">(7 Km)</span>
                </p>
                <p className={classes.extraTextItem}>
                  Pymble Ladies’ College{' '}
                  <span className="colorSecondary">(9 Km)</span>
                </p>
                <p className={classes.extraTextItem}>
                  Abbotsleigh School, Sydney{' '}
                  <span className="colorSecondary">(13 Km)</span>
                </p>
                <p className={classes.extraTextItem}>
                  Knox Grammar School{' '}
                  <span className="colorSecondary">(12 Km)</span>
                </p>
                <p className={classes.extraTextItem}>
                  Macquarie University{' '}
                  <span className="colorSecondary">(11 Km)</span>
                </p>
              </AnimateInView>
            </Grid>

            <Grid item md={12} sm={6} xs={12} className={classes.extraText}>
              <AnimateInView directionFrom="bottom">
                <h5 className={classes.extraTitle}>PARKS & RECREATION</h5>
                <p className={classes.extraTextItem}>Chatswood Golf Club</p>
                <p className={classes.extraTextItem}>Bales Park</p>
                <p className={classes.extraTextItem}>Beauchamp Park</p>
                <p className={classes.extraTextItem}>Chatswood Oval</p>
                <p className={classes.extraTextItem}>Lane Cove National Park</p>
              </AnimateInView>
            </Grid>

            <Grid item md={12} sm={6} xs={12} className={classes.extraText}>
              <AnimateInView directionFrom="bottom">
                <h5 className={classes.extraTitle}>
                  HOSPITALS & MEDICAL CENTRE
                </h5>
                <p className={classes.extraTextItem}>
                  Chatswood Medical & Dental Centre
                </p>
                <p className={classes.extraTextItem}>
                  Royal North Shore Hospital
                </p>
                <p className={classes.extraTextItem}>
                  North Shore Private Hospital
                </p>
              </AnimateInView>
            </Grid>

            <Grid item md={12} sm={6} xs={12} className={classes.extraText}>
              <AnimateInView directionFrom="bottom">
                <h5 className={classes.extraTitle}>ART & MOVIE</h5>
                <p className={classes.extraTextItem}>The Concourse</p>
                <p className={classes.extraTextItem}>The Zenith Theatre</p>
                <p className={classes.extraTextItem}>Hoyts Cinema</p>
              </AnimateInView>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.box2}>
        <Box className={classes.box2Inner}>
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{ marginBottom: 50 }}
          >
            <AnimateInView directionFrom="bottom">
              <h4 className={classes.box2Title}>Local eats</h4>
            </AnimateInView>
            <AnimateInView directionFrom="bottom">
              <span className={classes.box2TitleSub}>& treats</span>
            </AnimateInView>
          </Grid>

          <Grid
            className={classes.extraRow}
            container
            justify="space-between"
            alignItems="flex-end"
            spacing={3}
          >
            <Grid
              className={classes.extraItem}
              item
              md={5}
              xs={12}
              container
              direction="column"
            >
              <div className={classes.extraImage}>
                <img src={require('./images/extra7.png')} alt="" />
              </div>
              <div className={classes.extraText}>
                <h5 className={classes.extraTitle}>Shopping</h5>
                <p className={classes.extraTextItem}>Westfield</p>
                <p className={classes.extraTextItem}>Chatswood Chase</p>
                <p className={classes.extraTextItem}>Mandarin Centre</p>
              </div>
            </Grid>

            <Grid
              className={classes.extraItem}
              item
              md={6}
              xs={12}
              container
              direction="column"
            >
              <div className={classes.extraImage}>
                <img src={require('./images/extra8.png')} alt="" />
              </div>
              <div className={classes.extraText}>
                <h5 className={classes.extraTitle}>Cafés & Restaurants</h5>

                <p className={classes.extraTextItem}>Chimichuri</p>
                <p className={classes.extraTextItem}>Flower Child Chatswood</p>
                <p className={classes.extraTextItem}>Café Markus</p>
                <p className={classes.extraTextItem}>Gram Café & Pancakes</p>
                <p className={classes.extraTextItem}>Shuk Chatswood</p>
                <p className={classes.extraTextItem}>Manpuku Chatswood</p>
                <p className={classes.extraTextItem}>Din Tai Fung</p>
                <p className={classes.extraTextItem}>General Chao</p>
                <p className={classes.extraTextItem}>Mama Mulan</p>
              </div>
            </Grid>
          </Grid>

          <Grid
            className={classes.extraRow}
            container
            justify="space-around"
            alignItems="center"
            spacing={3}
          >
            <Grid
              className={classes.extraItem}
              item
              md={5}
              xs={12}
              container
              direction="column"
            >
              <div className={classes.extraImage}>
                <img src={require('./images/extra9.png')} alt="" />
              </div>
              <div className={classes.extraText}>
                <h5 className={classes.extraTitle}>Entertainments</h5>
                <p className={classes.extraTextItem}>Hoyts Westfield</p>
                <p className={classes.extraTextItem}>Hoyts Mandarin</p>
                <p className={classes.extraTextItem}>The Concourse</p>
                <p className={classes.extraTextItem}>The Zenith Theatre</p>
                <p className={classes.extraTextItem}>Strike Bowling</p>
                <p className={classes.extraTextItem}>Chatswood RSL Club</p>
              </div>
            </Grid>

            <Grid
              className={classes.extraItem}
              item
              md={4}
              xs={12}
              container
              direction="column"
            >
              <div className={classes.extraImage}>
                <img src={require('./images/extra10.png')} alt="" />
              </div>
              <div className={classes.extraText}>
                <h5 className={classes.extraTitle}>Parks & Recreation</h5>
                <p className={classes.extraTextItem}>Chatswood Golf Club</p>
                <p className={classes.extraTextItem}>Bales Park</p>
                <p className={classes.extraTextItem}>Beauchamp Park</p>
                <p className={classes.extraTextItem}>Chatswood Oval</p>
                <p className={classes.extraTextItem}>Lane Cove National Park</p>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <FormRegister />
    </Layout>
  );
};

export default Location;
