import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#252121',
      padding: '30px 40px 80px',
      [theme.breakpoints.up('sm')]: {
        minHeight: 'calc(100vh - 80px)',
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: 'calc(100vh - 56px)',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '30px 20px 60px',
      },
    },
    header: {
      width: '20%',
      [theme.breakpoints.down('xs')]: {
        width: '50%',
      },
    },
    slogan: {
      marginTop: 50,
      color: 'white',
      fontSize: 24,
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
      '& .underline': {
        borderBottom: '1px solid white',
        fontFamily: '"Comic Neue", cursive',
        paddingBottom: 3,
      },
      '& .yellow': {
        color: theme.color.yellow,
        borderColor: theme.color.yellow,
      },
      '& .italic': {
        fontFamily: '"Comic Neue", cursive',
        fontStyle: 'italic',
      },
    },
    form: {
      width: '70%',
      margin: '30px 0 10px',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
    tip: {
      marginTop: 8,
      color: 'white',
      fontSize: 8,
      textAlign: 'center',
    },
  }),
);
