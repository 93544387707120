import { createMuiTheme, Theme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    color: {
      [_: string]: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    color?: {
      [_: string]: string;
    };
  }
}

const theme: Theme = createMuiTheme({
  color: {
    yellow: '#E1AA49',
    green: '#a4b591',
  },
  palette: {
    primary: { main: '#262121' },
    secondary: { main: '#F8C44D' },
  },
});

export default theme;
