import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import {
  Grid,
  GridProps,
  TextField,
  TextFieldProps,
  Button,
  Snackbar,
  CircularProgress,
  withStyles,
  Theme,
} from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';

import AnimateInView from '../AnimateInView';

import useStyles from './styles';

const FormInput: React.ComponentType<TextFieldProps> = withStyles(() => ({
  root: {
    width: '100%',
    '& .MuiFormLabel-root': {
      color: 'white',
    },
    '& .MuiInputBase-root': {
      color: 'white',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#979797',
    },
    '& .MuiInput-underline::before': {
      borderBottomWidth: 1,
      borderBottomColor: '#979797',
    },
    '& .MuiInput-underline::after': {
      borderBottomWidth: 1,
      borderBottomColor: 'white',
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: '#f44336',
    },
  },
}))(TextField);

const Submit = withStyles((theme: Theme) => ({
  root: {
    width: '100%',
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderImage: 'linear-gradient(to right, #BA7D16, #F2CA48)',
    borderImageSlice: 1,
    lineHeight: '54px',
    [theme.breakpoints.down('xs')]: {
      lineHeight: 1.75,
    },
  },
  disabled: {
    color: `${theme.palette.secondary.light} !important`,
    borderColor: `${theme.palette.secondary.light} !important`,
  },
}))(Button);

type FormRegisterFields = {
  firstName: string;
  surname: string;
  mobile: string;
  email: string;
};

type IFormComponentProps = {} & React.FormHTMLAttributes<any>;

const IFormComponent: React.FC<IFormComponentProps> = props => {
  const defaultValues: FormRegisterFields = {
    firstName: '',
    surname: '',
    mobile: '',
    email: '',
  };
  const { handleSubmit, errors, control } = useForm<FormRegisterFields>({
    defaultValues,
  });
  const onSubmit = (fields: FormRegisterFields) => {
    setSubmitting(true);

    axios
      .post('/api/register', {
        ...fields,
      })
      .then(response => {
        const code = _.get(response, 'data.code', 0);
        const message = _.get(response, 'data.message');

        if (code === 0) {
          setSnackbarText('Register success !');
        } else {
          setSnackbarText(message);
        }
      })
      .catch(error => {
        const message = _.get(error, 'response.data.message', error.message);

        setSnackbarText(message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const [snackbarText, setSnackbarText] = React.useState<string | null>(null);
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...props}>
      <Grid container spacing={4} justify="space-between">
        <Grid item md={6} xs={12}>
          <AnimateInView directionFrom="bottom">
            <Controller
              as={
                <FormInput
                  label="First name"
                  error={!_.isEmpty(errors.firstName)}
                />
              }
              name="firstName"
              control={control}
              rules={{ required: true }}
            />
          </AnimateInView>
        </Grid>
        <Grid item md={6} xs={12}>
          <AnimateInView directionFrom="bottom">
            <Controller
              as={
                <FormInput label="Surname" error={!_.isEmpty(errors.surname)} />
              }
              name="surname"
              control={control}
              rules={{ required: true }}
            />
          </AnimateInView>
        </Grid>
        <Grid item md={6} xs={12}>
          <AnimateInView directionFrom="bottom">
            <Controller
              as={
                <FormInput label="Mobile" error={!_.isEmpty(errors.mobile)} />
              }
              name="mobile"
              control={control}
              rules={{ required: true }}
            />
          </AnimateInView>
        </Grid>
        <Grid item md={6} xs={12}>
          <AnimateInView directionFrom="bottom">
            <Controller
              as={<FormInput label="Email" error={!_.isEmpty(errors.email)} />}
              name="email"
              control={control}
              rules={{ required: true }}
            />
          </AnimateInView>
        </Grid>

        <Grid item md={12} xs={12}>
          <AnimateInView directionFrom="bottom">
            <Submit type="submit" disabled={isSubmitting} variant="outlined">
              {isSubmitting ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                'Submit'
              )}
            </Submit>
          </AnimateInView>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={_.toString(snackbarText)}
        open={!_.isEmpty(snackbarText)}
        onClose={() => setSnackbarText(null)}
        message={snackbarText}
      />
    </form>
  );
};

type FormRegisterProps = {} & GridProps;

const FormRegister: React.FC<FormRegisterProps> = props => {
  const classes = useStyles();
  const matchRegisterPage = useRouteMatch('/register');

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.container}
      {...props}
    >
      {!matchRegisterPage && (
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.header}
        >
          <img src={require('assets/images/home-bg.png')} alt="" />
        </Grid>
      )}

      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.slogan}
      >
        <AnimateInView directionFrom="bottom">
          <p style={{ marginBottom: 0 }}>
            <span className="underline">Express your </span>
            <span className="yellow underline italic">interest now.</span>
          </p>
        </AnimateInView>
        <AnimateInView directionFrom="bottom">
          <p style={{ marginTop: 12 }}>
            <span className="underline">Call </span>
            <span className="yellow underline italic">1800 380 038.</span>
          </p>
        </AnimateInView>
      </Grid>

      <IFormComponent className={classes.form} />

      <AnimateInView directionFrom="bottom">
        <div className={classes.tip}>
          By submitting your details above, you agree to your personal
          information being collected, held used and disclosed by us in
          accordance with our "Privacy Policy".
        </div>
      </AnimateInView>
    </Grid>
  );
};

export default FormRegister;
