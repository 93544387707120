import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => {
  return {
    box1: {
      position: 'relative',
      background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.primary.main} 25vw, ${theme.palette.secondary.main} 25vw, ${theme.palette.secondary.main})`,
      paddingTop: '30vw',
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    },

    sub: {
      marginLeft: 40,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    sub2: {
      marginLeft: 60,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },

      '& $teamText': {
        width: '90%',
      },
    },

    box2: {
      backgroundColor: 'white',
      padding: '80px 30px',
      [theme.breakpoints.down('sm')]: {
        padding: '30px 10px',
      },
      '& .title': {
        textAlign: 'center',
        fontSize: 30,
        marginBottom: 50,
        [theme.breakpoints.down('sm')]: {
          fontSize: 18,
          marginBottom: 20,
        },
      },
      '& .logoItem': {
        width: 110,
        height: 100,
        '&:nth-child(2)': {
          width: 150,
        },

        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        },
        [theme.breakpoints.down('sm')]: {
          width: 60,
          height: 50,
          '&:nth-child(2)': {
            width: 80,
          },
        },
      },
    },

    imageWrapper: {
      position: 'absolute',
      top: '4vw',
      left: '50%',
      width: '20vw',
      marginLeft: '-10vw',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },

    teamBox: {
      paddingBottom: 40,
    },
    team1: {
      marginTop: '-10vw',
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
    team2: {},
    team4: {
      [theme.breakpoints.up('sm')]: {
        marginTop: 350,
        marginLeft: -100,
        marginRight: 100,
      },
    },
    avatar: {
      backgroundColor: 'white',
      padding: 20,
      textAlign: 'center',
    },
    teamText: {
      padding: '20px 30px',
      width: '66%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      lineHeight: 1.8,
      color: 'white',
    },
    teamTextTitle: {
      fontSize: 20,
    },
    teamTextDetail: {
      fontSize: 12,
    },
  };
});
