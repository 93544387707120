import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from 'pages/Home';
import Location from 'pages/Location';
import Team from 'pages/Team';
import Register from 'pages/Register';

export default (
  <Switch>
    <Route exact path="/">
      <Home />
    </Route>
    <Route path="/location">
      <Location />
    </Route>
    <Route path="/team">
      <Team />
    </Route>
    <Route path="/register">
      <Register />
    </Route>
  </Switch>
);
