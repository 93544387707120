import { createStyles, makeStyles, Theme } from '@material-ui/core';

import imageArrow from 'assets/images/arrow.png';

export default makeStyles((theme: Theme) => {
  const laptopSpacing = [50, 40];
  const mobileSpacing = [20, 10];

  return createStyles({
    defaultBox: {
      [theme.breakpoints.up('sm')]: {
        minHeight: 'calc(100vh - 80px)',
      },
    },
    imageFit: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    imageWrapper: {
      position: 'relative',

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    fontBanner: {
      fontSize: 28,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    boxInset: {
      position: 'absolute',
      border: '8px solid white',
      boxSizing: 'content-box',
      width: '25%',
      paddingTop: '25%',

      '&>img': {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },

    slider: {
      width: '100%',
    },
    sliderItem: {
      position: 'relative',
    },

    box1: {
      position: 'relative',
      background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.primary.main} 50%, ${theme.palette.secondary.main} 50%, ${theme.palette.secondary.main})`,

      [theme.breakpoints.up('sm')]: {
        height: 'calc(100vh - 80px)',
        '& $defaultBox': {
          minHeight: 'auto',
          height: 'calc(100vh - 80px - 50px)',
        },
      },
      '& $sliderItem': {
        position: 'relative',
        width: '100%',
        '&::after': {
          content: '""',
          paddingTop: '50%',
          display: 'block',
        },

        '& img': {
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
          height: '100%',
        },
      },
      '& .text': {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: '13vh',
        width: '40%',
        padding: '10px 0 10px 30px',
        color: 'white',
        background:
          'linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, .3) 40%, transparent)',
        [theme.breakpoints.down('xs')]: {
          left: 10,
          right: 10,
          bottom: 10,
          width: 'auto',
          padding: 0,
          background: 'none',
        },
        '& .textTitle': {
          fontSize: 28,
          marginBottom: 20,
          [theme.breakpoints.down('xs')]: {
            fontSize: 18,
          },
        },
        '& .textDetail': {
          fontSize: 24,
          [theme.breakpoints.down('xs')]: {
            display: 'none',
          },
        },
      },
      '& .box1Arrow': {
        position: 'relative',
        height: 50,
        backgroundColor: theme.palette.secondary.main,
        '&::after': {
          content: '""',
          position: 'absolute',
          background: `url(${imageArrow})`,
          width: 46,
          height: 16,
          left: '50%',
          top: '50%',
          marginLeft: -23,
          marginTop: -8,
          animation: 'arrowdown 1s infinite',
        },
      },
    },

    box2: {
      padding: `${laptopSpacing[0]}px ${laptopSpacing[1]}px`,
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.down('xs')]: {
        padding: `${mobileSpacing[0]}px ${mobileSpacing[1]}px`,
      },
      '& .text': {
        textAlign: 'center',
      },
      '& .imageSide': {
        background: 'center center no-repeat',
        backgroundSize: 'cover',
        height: '45vw',
        [theme.breakpoints.down('xs')]: {
          height: 300,
        },
      },
      '& .textSide': {
        color: '#333333',
        padding: 10,
        fontSize: 18,
        backgroundColor: 'white',
        height: '45vw',
        [theme.breakpoints.down('xs')]: {
          height: 300,
        },
      },
    },

    box3: {
      position: 'relative',
      '& .text': {
        color: 'white',
        lineHeight: 1.2,
        position: 'absolute',
        left: 20,
        bottom: 28,
        width: '40%',
        zIndex: theme.zIndex.speedDial,
        [theme.breakpoints.down('sm')]: {
          width: '70%',
        },
      },
    },

    box4: {
      position: 'relative',
      padding: `${laptopSpacing[0]}px ${laptopSpacing[1]}px`,
      paddingLeft: 0,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: laptopSpacing[1],
      },
      [theme.breakpoints.down('xs')]: {
        padding: `${mobileSpacing[0]}px ${mobileSpacing[1]}px`,
      },
      background: `linear-gradient(to bottom, ${theme.color.green}, ${theme.color.green} 50%, white 50%, white)`,

      '& $boxInset': {
        top: 80,
        width: '20%',
        paddingTop: '20%',
        right: 'calc(2% + 30px)',
      },
      '& .image': {
        position: 'relative',
      },
      '& .textSide': {
        position: 'absolute',
        color: 'white',
        right: 20,
        bottom: 30,
        textAlign: 'right',
        lineHeight: 1.2,
      },
    },

    box5: {
      position: 'relative',
      '& .text': {
        color: 'white',
        lineHeight: 1.2,
        position: 'absolute',
        right: 20,
        bottom: 20,
        textAlign: 'right',
        width: '40%',
        [theme.breakpoints.down('sm')]: {
          width: '70%',
        },
      },
    },

    box6: {
      position: 'relative',
      padding: `${laptopSpacing[0]}px 0`,

      background: `linear-gradient(to bottom, ${theme.palette.secondary.main}, ${theme.palette.secondary.main} 50%, white 50%, white)`,
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '18%',
      },
      [theme.breakpoints.down('xs')]: {
        padding: `${mobileSpacing[0]}px 0`,
      },
      '& $boxInset': {
        top: '20%',
        left: 'calc(2% + 30px)',
      },
      '& .textSide': {
        position: 'absolute',
        left: 30,
        bottom: 60,
        lineHeight: 1.2,
        color: 'white',
        [theme.breakpoints.up('lg')]: {
          left: 'calc(18% + 30px)',
        },
        [theme.breakpoints.up('sm')]: {
          paddingLeft: 20,
          paddingRight: 20,
        },
        [theme.breakpoints.down('sm')]: {
          right: 20,
          width: 'auto',
        },
        '& .textTitle': {
          marginBottom: 0,
          letterSpacing: 2,
        },
        '& .textDetail': {
          fontSize: 12,
          marginBottom: 0,
        },
      },
    },

    box7: {
      position: 'relative',
      '& .text': {
        color: 'white',
        lineHeight: 1.2,
        position: 'absolute',
        right: 20,
        bottom: 20,
        width: '40%',
        [theme.breakpoints.down('sm')]: {
          width: '70%',
        },
      },
    },

    box8: {
      padding: `${laptopSpacing[0]}px ${laptopSpacing[1]}px`,
      background: `linear-gradient(to bottom, ${theme.color.green}, ${theme.color.green} 50%, white 50%, white)`,
      [theme.breakpoints.down('xs')]: {
        padding: `${mobileSpacing[0]}px ${mobileSpacing[1]}px`,
      },
      '& .underline': {
        borderBottom: '1px solid white',
        paddingBottom: 3,
        fontFamily: '"Comic Neue", cursive',
      },
      '& .yellow': {
        color: theme.color.yellow,
        borderColor: theme.color.yellow,
      },
      '& .italic': {
        fontFamily: '"Comic Neue", cursive',
        fontStyle: 'italic',
      },
      '& .text': {
        textAlign: 'center',
      },
      '& .textSide': {
        fontSize: 18,
        color: 'white',
        padding: 10,
        backgroundColor: theme.palette.primary.main,
        lineHeight: 2,
        [theme.breakpoints.down('sm')]: {
          padding: '50px 10px',
        },
        [theme.breakpoints.up('sm')]: {
          paddingBottom: 40,
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '2vw',
        },
      },
      '& .imageSide': {},
    },
  });
});
