import React from 'react';
import Layout from 'components/Layout';

import FormRegister from 'components/FormRegister';

type IComponentProps = {};

const Register: React.FC<IComponentProps> = props => {
  return (
    <Layout>
      <FormRegister />
    </Layout>
  );
};

export default Register;
