import { makeStyles, Theme } from '@material-ui/core';

import imageArrow from 'assets/images/arrow.png';

export default makeStyles((theme: Theme) => {
  const laptopSpacing = [50, 40];
  const mobileSpacing = [20, 10];

  return {
    defaultBox: {
      [theme.breakpoints.up('sm')]: {
        minHeight: 'calc(100vh - 80px)',
      },
    },
    imageWrapper: {
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        height: '100%',
      },
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    boxInset: {
      position: 'absolute',
      border: '8px solid white',
      boxSizing: 'content-box',
      width: '25%',
      paddingTop: '25%',
      '&>img': {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },

    box1: {
      background: `linear-gradient(to bottom, ${theme.palette.secondary.main}, ${theme.palette.secondary.main} 50%, white 50%, white)`,
      padding: `${laptopSpacing[0]}px ${laptopSpacing[1]}px`,

      [theme.breakpoints.down('xs')]: {
        padding: `${mobileSpacing[0]}px ${mobileSpacing[1]}px`,
      },
      '& .content': {
        backgroundColor: 'rgb(52, 52, 52)',
      },
      '& .image': {
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
      },
      '& .text': {
        color: 'white',
        marginTop: '1.8vw',
        padding: '10px 30px 10px 10px',

        '& .title': {
          marginBottom: 0,
          lineHeight: 1.5,
        },
        '& .titleBold': {
          fontSize: 22,
          color: theme.palette.secondary.main,
          letterSpacing: 3,
          fontWeight: 'bold',
        },
      },
      '& .colorSecondary': {
        color: theme.palette.secondary.main,
      },
      '& $extraText': {
        borderLeft: 0,
        paddingLeft: 8,
        lineHeight: 1.5,
        margin: '8px 0',
        '& + $extraText': {
          marginTop: 5,
        },
      },
      '& $extraTitle': {
        fontSize: 16,
        margin: 0,
        textTransform: 'uppercase',
        color: theme.palette.secondary.main,
        marginBottom: 3,
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
      },
      '& $extraTextItem': {
        margin: 0,
        fontSize: 13,
        '&::before': {
          content: '"●"',
          fontSize: 8,
          padding: 2,
          paddingRight: 8,
          verticalAlign: 'middle',
        },
      },
    },

    box2: {
      background: `linear-gradient(to bottom, ${theme.palette.secondary.main}, ${theme.palette.secondary.main} 20%, ${theme.color.green} 20%, ${theme.color.green} 70%, ${theme.palette.secondary.main} 70%, ${theme.palette.secondary.main})`,
      padding: `${laptopSpacing[0]}px ${laptopSpacing[1]}px`,

      [theme.breakpoints.down('xs')]: {
        padding: `${mobileSpacing[0]}px ${mobileSpacing[1]}px`,
      },
    },
    box2Inner: {
      backgroundColor: 'white',
      padding: `${laptopSpacing[0]}px ${laptopSpacing[1]}px`,
      [theme.breakpoints.down('xs')]: {
        padding: `${mobileSpacing[0]}px ${mobileSpacing[1]}px`,
      },
    },
    box2Title: {
      position: 'relative',
      fontSize: 20,
      margin: '0 0 10px 0',
      fontFamily: '"Comic Neue", cursive',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -8,
        left: -8,
        right: -8,
        height: 1,
        backgroundColor: '#333333',
      },
    },
    box2TitleSub: {
      color: '#ADBE99',
      marginTop: 10,
      marginBottom: 50,
      fontFamily: '"Comic Neue", cursive',
    },

    extraRow: {
      '& + $extraRow': {
        marginTop: 30,
      },
    },
    extraItem: {},
    extraImage: {
      width: '100%',
    },
    extraText: {
      borderLeft: '1px solid #666666',
      paddingLeft: 8,
      lineHeight: 1.5,
      margin: '8px 0',
    },
    extraTitle: {
      fontSize: 16,
      margin: 0,
      textTransform: 'uppercase',
    },
    extraTextItem: {
      margin: 0,
      fontSize: 13,
      '&::before': {
        content: '"●"',
        fontSize: 8,
        padding: 2,
        paddingRight: 8,
        verticalAlign: 'middle',
      },
    },

    box3: {
      height: '40vw',
      backgroundColor: '#494949',
    },
    box4: {
      height: '40vw',
      padding: 30,
      backgroundColor: '#F2F2F2',
    },
  };
});
