import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated, config } from 'react-spring';

type IComponentProps = {
  directionFrom?: 'top' | 'left' | 'right' | 'bottom';
} & React.HTMLAttributes<HTMLElement>;

const AnimateInView: React.FC<IComponentProps> = props => {
  const { directionFrom = 'left', style = {}, children, ...rest } = props;

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const { opacity, translate } = useSpring({
    opacity: inView ? 1 : 0,
    translate: inView ? 0 : 30,
    config: { mass: 20, ...config.molasses },
  });

  const transform = React.useCallback(({ directionFrom, value }) => {
    switch (true) {
      case directionFrom === 'top':
        return `translateY(-${value}px)`;

      case directionFrom === 'bottom':
        return `translateY(${value}px)`;

      case directionFrom === 'right':
        return `translateX(${value}px)`;

      case directionFrom === 'left':
      default:
        return `translateX(-${value}px)`;
    }
  }, []);

  return (
    <animated.div
      style={{
        opacity: opacity.interpolate(o => o),
        transform: translate.interpolate(t =>
          transform({ directionFrom, value: t }),
        ),
        ...style,
      }}
      ref={ref}
      {...rest}
    >
      {children}
    </animated.div>
  );
};

export default AnimateInView;
