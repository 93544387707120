import React from 'react';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Header from './Header';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minHeight: '100vh',
    },
    content: {
      flex: 1,
    },
  }),
);

type IComponentProps = {};

const Layout: React.FC<IComponentProps> = props => {
  const classes = useStyles();
  const { children } = props;

  return (
    <Grid container direction="column" className={classes.container}>
      <Header />

      <Grid item className={classes.content}>
        {children}
      </Grid>
    </Grid>
  );
};

export default Layout;
