import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './index';

interface GlobalState {
  fixedTextColor: 'white' | 'black';
}

const initialState: GlobalState = {
  fixedTextColor: 'white',
};

export const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setFixedTextColor: (state, action: PayloadAction<'white' | 'black'>) => {
      state.fixedTextColor = action.payload;
    },
  },
});

export const { setFixedTextColor } = slice.actions;

export const selectFixedTextColor = (state: RootState) =>
  state.global.fixedTextColor;

export default slice.reducer;
