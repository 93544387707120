import React from 'react';
import clsx from 'clsx';
import Layout from 'components/Layout';
import { Grid, Box, Hidden } from '@material-ui/core';
import AnimateInView from 'components/AnimateInView';
import Carousel from 'nuka-carousel';

import FormRegister from 'components/FormRegister';
import useStyles from './styles';

type IComponentProps = {};

const Home: React.FC<IComponentProps> = props => {
  const classes = useStyles();

  const carouselProps = {
    autoplay: true,
    height: '100%',
    defaultControlsConfig: {
      prevButtonStyle: { display: 'none' },
      nextButtonStyle: { display: 'none' },
    },
  };

  return (
    <Layout>
      <Box className={clsx(classes.box1)}>
        <Box className={classes.defaultBox}>
          <Carousel className={classes.slider} {...carouselProps}>
            <div className={classes.sliderItem}>
              <img src={require('./images/box1.jpeg')} alt="" />
              <div className="text">
                <div className="textTitle">
                  A LIMITED RELEASE - COLLECTION OF NINE
                </div>
              </div>
            </div>
            <div className={classes.sliderItem}>
              <img src={require('./images/box1-slider.jpeg')} alt="" />
              <div className="text">
                <div className="textTitle">
                  A LIMITED RELEASE - COLLECTION OF NINE
                </div>
              </div>
            </div>
          </Carousel>
        </Box>
        <Box className="box1Arrow" />
      </Box>

      <AnimateInView directionFrom="bottom">
        <Grid container className={classes.box2}>
          <Grid
            container
            item
            md={6}
            xs={12}
            className="imageSide"
            direction="column"
            alignItems="center"
            justify="center"
            style={{ backgroundImage: `url(${require('./images/box2.jpeg')})` }}
          >
            <div className="text" style={{ color: 'white' }}>
              <div style={{ fontSize: 20, fontWeight: 'bold' }}>CHATSWOOD</div>
              <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                G · A · R · D · E · N
              </div>
              <div style={{ fontSize: 14, lineHeight: 2.5 }}>
                2-6 Kooringa road Chatswood
              </div>
            </div>
          </Grid>
          <Grid
            container
            item
            md={6}
            xs={12}
            direction="column"
            className="textSide"
            justify="center"
            alignItems="center"
          >
            <img
              style={{ width: '40%', marginBottom: 4 }}
              src={require('./images/box2-inset.jpg')}
            />
            <div className="text" style={{ fontSize: 14 }}>
              TRANQUIL · FAMILY · UNIQUE · LUXURY · COLLECTIONS
            </div>
          </Grid>
        </Grid>
      </AnimateInView>

      <AnimateInView directionFrom="bottom">
        <Grid container className={classes.box4}>
          <Grid item md={10} xs={12} className="image">
            <img
              className={classes.imageFit}
              src={require('./images/box4.jpeg')}
            />
            <Grid
              item
              container
              direction="column"
              md={3}
              xs={10}
              className={clsx(classes.fontBanner, 'textSide')}
            >
              <div>CRAFTSMANSHIP</div>
              <div>AND NATURAL</div>
              <div>BEAUTY IN EVERY DETAIL</div>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item className={classes.boxInset}>
              <img src={require('./images/box4-inset.jpeg')} alt="" />
            </Grid>
          </Hidden>
        </Grid>
      </AnimateInView>

      <Box className={classes.box3}>
        <div className={clsx(classes.fontBanner, 'text')}>
          <AnimateInView directionFrom="bottom">
            <div>STYLE & DESIGN</div>
            <div>TO CREATE</div>
            <div>A ‘FOREVER HOME’</div>
          </AnimateInView>
        </div>
        <AnimateInView directionFrom="bottom">
          <img
            className={classes.imageFit}
            style={{ verticalAlign: 'middle' }}
            src={require('./images/box3.jpeg')}
            alt=""
          />
        </AnimateInView>
      </Box>

      <AnimateInView directionFrom="bottom">
        <Box className={classes.box6}>
          <Hidden mdDown>
            <Grid item className={classes.boxInset}>
              <img src={require('./images/box6-inset.png')} alt="" />
            </Grid>
          </Hidden>
          <img
            className={classes.imageFit}
            src={require('./images/box6.jpeg')}
            alt=""
          />
          <Grid item container md={5} sm={10} className="textSide">
            <Grid item container alignContent="flex-start">
              <div className={clsx(classes.fontBanner, 'text')}>
                <div>COME HOME TO</div>
                <div>TRANQUIL LUXURY</div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </AnimateInView>

      <AnimateInView directionFrom="bottom">
        <Box className={classes.box5}>
          <div className={clsx(classes.fontBanner, 'text')}>
            <div>BEAUTIFULLY CRAFTED</div>
            <div>SPACES FOR RELAXATION</div>
          </div>
          <img
            className={classes.imageFit}
            style={{ verticalAlign: 'middle' }}
            src={require('./images/box5.jpeg')}
            alt=""
          />
        </Box>
      </AnimateInView>

      <AnimateInView directionFrom="bottom">
        <Grid container className={classes.box8}>
          <Grid item md={6} xs={12} className="imageSide">
            <img
              className={classes.imageFit}
              style={{ verticalAlign: 'middle' }}
              src={require('./images/box8.jpeg')}
              alt=""
            />
          </Grid>
          <Grid
            container
            item
            md={6}
            xs={12}
            direction="column"
            className="textSide"
            justify="center"
          >
            <div className="text">
              <span className="underline">Step into </span>
              <span className="italic yellow underline">privacy</span>
            </div>
            <div className="text">
              <span className="underline">and </span>
              <span className="italic yellow underline">serenity.</span>
            </div>
          </Grid>
        </Grid>
      </AnimateInView>

      <FormRegister />
    </Layout>
  );
};

export default Home;
